@import '../../../../styles/mixins';

.accordionContainer {
  box-shadow: none;
  width: 100%;
  background-color: #ffffff;
  border-radius: 16px !important;
  overflow: hidden !important;
  padding: 20px;
  .titleBlock {
    display: flex;
    gap: 20px;
    align-items: center;
    flex-wrap: wrap;
    .disputeIcon {
      padding: 5px 12px;
      border: 1px solid rgba(211, 47, 47, 1);
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .title {
      color: rgba(20, 52, 113, 1);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;

      @include for-mobile-max_620 {
        font-size: 12px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-right: 10px;
    .statusName {
      white-space: nowrap;
    }
  }

  .accordionDetails {
    padding: 0;

    .content {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      gap: 12px;

      .itemInfoRow {
        display: flex;
        gap: 20px;
        align-items: center;
      }
      .sellerComment {
        align-items: flex-start;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
}
.boldText,
.text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
}
.thinText {
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.text {
  font-weight: 400;
}
.optionsMenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  ul {
    padding: 0;
  }
  .menuItem {
    display: flex;
    align-items: center;
    gap: 8px;

    .optionName {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }
  }
}

.scrollContainer::-webkit-scrollbar-track {
  background-color: #d4d4d4;
}
.scrollContainer::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 8px;
}
.slider {
  display: flex;
  align-items: center;
  column-gap: 8px;
  flex-wrap: nowrap;
  padding-bottom: 12px;
  .sliderImage,
  .sliderImageUploaded {
    width: 90px;
    height: 90px;
    min-width: 90px;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    .imageLoader {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: rgba(0, 0, 0, 0.2);
      backdrop-filter: blur(6px);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      .reloadBtn {
        width: 30px;
        height: 30px;
        cursor: pointer;
        svg > path {
          fill: #ffffff;
        }
      }
      .lineProgress {
        border-radius: 2px;
        width: 80px;
        height: 4px;
        background-color: rgba(0, 0, 0, 0.6);
        span {
          background-color: #ffffff;
        }
      }
    }
    .removeImage {
      position: absolute;
      display: flex;
      background: rgba(0, 0, 0, 0.4);
      backdrop-filter: blur(2px);
      justify-content: center;
      align-items: center;
      cursor: pointer;
      top: 6px;
      width: 24px;
      height: 24px;
      right: 6px;
      border-radius: 2px;
      z-index: 1;
    }
    .image,
    .imageUploaded {
      height: 90px;
      width: 90px;
      border-radius: 12px;
      min-width: 90px;
      img {
        object-fit: cover;
        border-radius: 12px;
      }
    }
  }
  .sliderImageUploaded {
    width: 100px !important;
    height: 100px !important;
    min-width: 100px;
    position: relative;
    .playerIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
      .videoPlayBtn {
        path {
          fill: white;
        }
      }
    }
    .imageUploaded {
      width: 100px !important;
      height: 100px !important;
      object-fit: cover;
    }
  }
}
.video-container {
  position: relative;
  width: 100%;
  max-width: 600px; /* Задайте максимальную ширину вашего видео */
}
.video {
  width: 100%;
  height: auto;
}
.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 1;
}

.modalBtn {
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 32px;
}

.modalForm {
  display: flex;
  flex-direction: column;
  padding-top: 8px;
}
.inputSelect {
  width: 100%;
}
.radioItem {
  height: fit-content;
}
.radioGroup {
  gap: 12px;
}
.textarea {
  position: relative;
  width: 100%;
  &[data-type='location'] {
    cursor: pointer !important;
  }
  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }
  & > label {
    font-style: normal;
    font-weight: 400;
    background-color: #ffffff;
    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }
  & > div {
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }
  & > div > input {
    padding: 12.5px 16px;
  }
  & > div > fieldset {
    border-radius: 12px;
    border: 1px solid #efefef;
    & > legend {
      font-style: normal;
      font-weight: 400;
    }
  }
}
