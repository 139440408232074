@import '../../../../styles/_mixins.scss';

.middleContent {
  font-family: 'Montserrat';
  max-width: 1020px;
  display: flex;
  flex-direction: column;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;
  padding: 0 0 110px 0;
  gap: 16px;

  .loadingBox {
    display: flex;
    justify-content: center;
    padding: 64px 0;
    width: 100%;
  }

  @media only screen and (max-width: 750px) {
    flex-wrap: wrap;
  }

  .container {
    padding: 24px 40px;
    @include for-mobile-max_767 {
      padding: 24px 20px;
    }
    border-radius: 12px;
    background-color: #ffffff;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 12px;

      .leftAside {
        display: flex;
        align-items: flex-start;
        gap: 12px;
        padding-right: 40px;

        .backBtn {
          cursor: pointer;
        }
        .orderId {
          font-size: 24px;
          font-weight: 600;
          line-height: 24px;
          text-align: left;
          color: #143471;
          word-break: break-all;
          @include for-mobile-max_767 {
            font-size: 18px;
          }
        }
      }
      .statusBlock {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 12px;
        color: #ffffff;
        border-radius: 12px;
        margin-right: 30px;
      }
      .options {
        cursor: pointer;
        position: absolute;
        right: 0;
        top: 5px;
        @include for-mobile-max_767 {
          top: 0;
        }
      }
    }

    .contactsHeader {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      row-gap: 8px;

      .contactInfos,
      .contactInfosExpanded {
        display: flex;
        column-gap: 20px;
        row-gap: 8px;
        align-items: center;
        flex-wrap: wrap;

        .title {
          display: flex;
          gap: 12px;
          align-items: center;

          .text {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            color: #000000;
          }
          svg {
            height: 24px;
            width: 24px;
          }
        }

        .userInfos {
          display: flex;
          gap: 20px;
          row-gap: 8px;
          align-items: center;
          flex-wrap: wrap;

          .infoItem {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }

      .contactInfosExpanded {
        flex-direction: column;
        column-gap: 20px;
        row-gap: 8px;
        align-items: flex-start;
        .userInfos {
          @media screen and (min-width: 1100px) {
            //margin-left: 34px;
          }
        }
      }
      .actions {
        display: flex;
        align-items: center;
        gap: 12px;

        .btn {
          display: flex;
          justify-content: center;
          align-items: center;

          span:first-child {
            display: inline-grid !important;

            svg {
              height: 24px;
              width: 24px;

              g > path {
                fill: #18397a !important;
              }

              > path {
                fill: #18397a !important;
              }
            }
          }
        }
      }
    }

    .lastComment {
      margin-top: 20px;
      display: flex;

      .textarea {
        width: 100%;
      }
    }

    .orderDateContainer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .orderDate {
        color: #000000;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        text-align: left;

        @include for-mobile-max_620 {
          font-size: 14px !important;
        }
      }

      .orderDateBlock {
        display: flex;
        gap: 12px;
        align-items: center;

        .orderDateTitle {
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;

          @include for-mobile-max_620 {
            font-size: 14px !important;
          }
        }
      }
    }

    .paymentBlock {
      display: flex;
      gap: 8px;
      flex-direction: column;

      .paymentHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 500;

        .titleBlock {
          display: flex;
          gap: 12px;
          align-items: center;
        }

        .titleUnderline {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 24px;
          text-decoration-line: underline;
          cursor: pointer;
        }

        .warningInfoBlock {
          display: flex;
          padding: 2px 12px;
          align-items: center;
          gap: 8px;
          border-radius: 12px;
          background-color: #efefef;
          color: #212121;
          margin: 6px 0;

          .title {
            font-size: 14px;
            font-weight: 400;
            line-height: 24px;
          }
        }

        .title {
          color: #000000;
          font-weight: 500;
          font-size: 16px;

          @include for-mobile-max_620 {
            font-size: 14px !important;
          }
        }

        .paymentActions {
          display: flex;
          gap: 20px;

          @include for-tablet-landscape-max_1024 {
            gap: 16px;
          }

          @include for-mobile-max_620 {
            gap: 12px;
          }
        }
      }

      .descriptionBlock {
        width: 100%;
        display: flex;
        gap: 8px;
        flex-direction: column;
        padding-left: 36px;

        @include for-mobile-max_620 {
          padding: 0;
        }

        .descriptionItem {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          line-height: 24px;
          flex-wrap: wrap;
          row-gap: 10px;

          .itemName {
            color: #555555;
            font-weight: 400;
            font-size: 16px;

            @include for-mobile-max_620 {
              font-size: 14px !important;
            }
          }

          .pickupSelect {
            min-width: 260px;

            .fullSelect {
              width: 100% !important;
            }
          }

          .deliveryDate {
            max-width: 260px;
            max-height: 48px;
          }

          .itemPrice {
            color: #000000;
            font-weight: 500;
            font-size: 16px;

            @include for-mobile-max_620 {
              font-size: 14px !important;
            }
          }
        }

        .verticalCenter {
          justify-content: flex-start;
          gap: 12px;
          padding: 0;
          @include for-mobile-max_620 {
            flex-direction: column;
            gap: 8px;
            align-items: flex-start;
          }
        }
      }
    }
  }

  .timerBlock {
    flex-wrap: wrap;
    display: flex;
    gap: 12px;
    align-items: center;

    @include for-mobile-max_620 {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      text-align: left;
    }

    .warningInfoBlock {
      display: flex;
      padding: 2px 12px;
      align-items: center;
      gap: 8px;
      border-radius: 12px;
      background-color: #efefef;
      color: #212121;
      margin: 6px 0;

      .title {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }

  .contacts {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 12px;

    @include for-mobile-max_767 {
      padding: 12px;
      border: 1px solid #efefef;
      border-radius: 12px;
    }
    .contactInfo {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .topRow {
        display: flex;
        align-items: center;
        gap: 8px;

        .text {
          font-size: 14px;
          font-weight: 500;
          line-height: 24px;
          text-align: left;
          color: #000000;
        }
      }
      .infoItems {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        .infoItem {
          font-size: 14px;
          font-weight: 400;
          line-height: 24px;
          text-align: left;
          color: #555555;
        }
      }
    }
    .messageIcon {
      svg > path {
        fill: #143471;
      }
    }
  }

  .accordionContainer {
    margin: 0 !important;
    box-shadow: none;
    padding: 24px 40px;
    @include for-mobile-max_767 {
      padding: 24px 20px;
    }
    width: 100%;
    background-color: #ffffff;
    border-radius: 16px !important;
    overflow: hidden !important;
    .accordionTitle {
      display: flex;
      gap: 8px;
      align-items: center;
      font-family: 'Montserrat';
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      justify-content: space-between;
      width: 100%;
      margin-right: 12px;
      .icons {
        display: flex;
        gap: 20px;
      }
    }
    .titleExpanded {
      margin: 20px;
    }
    &:before {
      display: none;
    }

    .accordionDetails {
      padding: 0;
      display: flex;
      font-size: 16px;
      flex-direction: column;
      gap: 24px;

      .contentTitle {
        margin-top: 35px;
        font-size: 18px !important;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
      }

      .subTitle {
        font-size: 16px;
        font-weight: 500;
      }
      .text {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }
      .textBold {
        font-weight: 500;
      }
      .textWithBg,
      .textWithBgRed {
        padding: 4px 12px;
        border-radius: 12px;
        font-size: 12px !important;
        font-weight: 500;
        line-height: 32px;
        text-align: left;
        color: #000000;
        background-color: #ffd54233;
      }
      .textWithBgRed {
        background-color: #ff584233 !important;
      }
    }
  }

  .orderActionGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @include for-mobile-max_620 {
      flex-direction: column;
    }

    .orderAction {
      display: flex;
      gap: 20px;
      @include for-mobile-max_620 {
        flex-direction: column;
      }

      .buttonItem {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        @include for-mobile-max_620 {
          max-width: unset !important;
        }
        white-space: nowrap;
        img {
          filter: brightness(0) saturate(100%) invert(15%) sepia(64%) saturate(2517%)
            hue-rotate(210deg) brightness(90%) contrast(91%);
        }
      }

      .payButton {
        padding: 10px 110px !important;
        @include for-mobile-max_620 {
          padding: 10px 43px !important;
        }
      }

      @include for-mobile-max_620 {
        width: 100%;
        justify-content: space-between;
      }

      .transparentButton {
        width: 100%;
        @include for-mobile-max_620 {
          max-width: unset !important;
        }
      }
    }
  }

  .proposeBtn {
    background-color: transparent !important;
  }

  .greenBtn {
    background-color: #4caf50 !important;
    color: #ffffff !important;
    border: none !important;
  }
}

.optionsMenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  ul {
    padding: 0;
  }
  .menuItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.canceledInfo {
  display: flex;
  align-items: flex-start;
  gap: 12px;

  svg {
    height: 24px;
    width: 24px;
    path {
      fill: rgba(211, 47, 47, 1);
    }
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .status {
    color: rgba(211, 47, 47, 1);
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .reason {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  .comment {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgba(85, 85, 85, 1);
  }
}
