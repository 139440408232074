@import '../../../styles/_mixins.scss';

.middleContent {
  max-width: 1020px;
  display: flex;
  margin-left: 1.2% !important;
  margin-right: 1.2% !important;
  padding: 0 0 60px 0;
  gap: 20px;

  @media only screen and (max-width: 750px) {
    flex-wrap: wrap;
  }
}

.loadingContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  border-radius: 12px;
  background-color: #ffffff;
}

.cartListContainer {
  width: 100%;
  display: flex;
  gap: 20px;

  @include for-tablet-landscape-max_1024 {
    gap: 16px;
    flex-direction: column;
  }
}

.creditOptionRadiBlock {
  margin-top: 4px;
}

.radioTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.blockRadio {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 16px;
}

.containerPayment {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 12px;
  background: #ffffff;

  .paymentTypes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;

    .paymentType {
      display: flex;
      align-items: center;
      padding: 0 20px;
      gap: 12px;
      border-radius: 4px;
      border: 1px solid #5e56f2;
      cursor: pointer;

      .name {
        color: #5e56f2;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 48px;
        text-transform: uppercase;
      }
    }

    .paymentTypeDisabled {
      border: 1px solid #efefef;
      cursor: not-allowed;

      .name {
        color: #efefef;
      }
    }
  }

  .description {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.containerCheckout {
  width: 292px;
  max-width: 292px;
  min-width: 292px;
  height: fit-content;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  position: relative;

  border-radius: 12px;
  background: #ffffff;
  box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.04);

  @media only screen and (max-width: 1024px) {
    width: 100%;
    max-width: 100%;
  }

  .button {
    width: 100%;
    max-width: 100%;
    text-transform: uppercase;
  }

  .disableButton {
    background-color: #8c9dbd;
  }

  .checkout {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

    .yourCart,
    .totalPrice {
      width: 100%;

      .title,
      .price {
        color: #555555;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: 0.16px;
      }
    }

    .totalPrice {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-top: 16px;
      border-top: 1px solid #efefef;
    }

    .yourCart {
      .cartTotalBlock {
        display: flex;
        flex-direction: column;
        gap: 12px;
        margin-top: 10px;

        .subtotal,
        .discount {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title {
            color: #555555;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
          }

          .price,
          .discountPrice {
            color: #212121;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
          }

          .discountPrice {
            color: #d32f2f;
          }
        }
      }
    }

    .description {
      color: #555555;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .emptyMessage {
    color: #555555;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
}

.btnLoader {
  height: 60px;

  > svg {
    color: #f1f1f1 !important;
  }
}
.cardsBlock {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-column-gap: 6px;
  grid-row-gap: 6px;

  .addCardBtn {
    white-space: nowrap;
    display: flex;
    gap: 12px;
    align-items: center;

    svg > path {
      fill: #143471;
    }
  }
}
.addCardBtn {
  white-space: nowrap;
  display: flex;
  gap: 12px;
  align-items: center;

  svg > path {
    fill: #ffffff;
  }
}
