.productImageSwiper {
  width: 100%;
  padding-bottom: 18px !important;
  display: none !important;

  @media screen and (max-width: 800px) {
    display: block !important;
  }

  @media screen and (max-width: 650px) {
    height: 284px;
  }

  .productImageSwiperSlide {
    display: flex;
    justify-content: center;
    border-radius: 12px;
    overflow: hidden;
  }
}
.video,
.videoRight {
  height: 80px;
  width: 80px;
  border-radius: 12px;
  object-fit: cover;

  img {
    object-fit: cover;
    border-radius: 12px;
  }
}

.videoRight {
  height: 80%;
  width: 80%;
}
