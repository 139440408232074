@import '../../../../../styles/_mixins.scss';

.container {
  width: 100%;
  padding: 24px 40px;
  border-radius: 12px !important;
  background: #ffffff;
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 !important;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #212121;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;

      .icon {
        cursor: pointer;
      }
    }
  }

  .accordionSummary {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .accordionTitle {
      font-size: 16px;
      font-weight: 600;
      line-height: 24px;
      color: #212121;
    }

    .headerText {
      font-size: 16px;
      font-weight: 400 !important;
      line-height: 24px !important;
      text-align: right;
    }
  }
  .orderActionGroup {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;

    @include for-mobile-max_620 {
      flex-direction: column;
    }

    .orderAction {
      margin-top: 16px;
      display: flex;
      gap: 20px;
      @include for-mobile-max_620 {
        flex-direction: column;
      }

      .buttonItem {
        width: 100%;
        display: flex;
        gap: 12px;
        align-items: center;
        @include for-mobile-max_620 {
          max-width: unset !important;
        }
        white-space: nowrap;
        img {
          filter: brightness(0) saturate(100%) invert(15%) sepia(64%) saturate(2517%)
            hue-rotate(210deg) brightness(90%) contrast(91%);
        }
      }

      .payButton {
        padding: 10px 110px !important;
        @include for-mobile-max_620 {
          padding: 10px 43px !important;
        }
      }

      @include for-mobile-max_620 {
        width: 100%;
        justify-content: space-between;
      }

      .transparentButton {
        width: 100%;
        @include for-mobile-max_620 {
          max-width: unset !important;
        }
        background-color: transparent !important;
      }
    }
  }
}

.warningNote {
  display: flex;
  align-items: center;
  svg > path {
    fill: #eb793f;
  }

  .note {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: right !important;
    .warningText {
      color: #eb793f;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
    }
  }
}
