@import '/src/styles/mixins';

.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.changesItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.statusChanges {
  display: flex;
  gap: 20px;
  align-items: center;

  .orderStatus {
    padding: 8px 12px;
    border-radius: 12px;
    font-size: 14px;
    font-weight: 500;
    color: #ffffff;
    white-space: nowrap;
  }
}
.changedBlock {
  display: flex;
  align-items: center;
  gap: 4px;
  line-height: 20px !important;
}

.oldValue {
  color: #757575;
  font-weight: 400;
  font-size: 12px !important;
}

.changedValue {
  font-size: 14px !important;
  font-weight: 500;
  color: #000000;
  white-space: normal;
  word-break: break-all;
}

.changesProduct {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 10px;

  .productDesc {
    display: flex;
    min-width: 200px;
    max-width: 200px;
    align-items: center;
    gap: 20px;

    .productInfo {
      display: flex;
      align-items: center;
      gap: 12px;

      .productTitle {
        display: flex;
        flex-direction: column;
        gap: 2px;
      }
    }
  }
}

.changedInfoBlock {
  display: flex;
  flex-direction: column;
  gap: 2px;

  .changedName {
    font-weight: 400;
    line-height: 20px;
    font-size: 12px !important;
  }
}

.changedTotal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  row-gap: 10px;
  flex-wrap: wrap;
}
