.container {
  width: 100%;
  padding: 24px 40px;
  border-radius: 12px !important;
  background: #ffffff;
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 !important;

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #212121;
  }

  .description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  .fileItem {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .name {
      display: flex;
      align-items: center;
      gap: 12px;
    }

    .actions {
      display: flex;
      align-items: center;
      gap: 20px;

      .icon {
        cursor: pointer;
      }
    }
  }
}

.warningNote {
  display: flex;
  align-items: center;
  svg > path {
    fill: #eb793f;
  }

  .note {
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    text-align: right !important;
    .warningText {
      color: #eb793f;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: right;
    }
  }
}
