@import '../../../../styles/mixins';

.loader {
  border-radius: 12px !important;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;

  .orderStatusBlock {
    position: absolute;
    right: 55px;
    display: none;

    @include for-mobile-max_620 {
      display: block;
    }

    .menuButton {
      position: absolute;
      width: 30px;
      height: 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px !important;
    }
  }

  .listContainer,
  .listContainerUnable {
    padding: 20px 40px;
    border-radius: 12px;
    background: #ffffff;

    @include for-mobile-max_620 {
      padding: 20px !important;
    }

    .listHeader,
    .productList > .productListHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 5px;
      border-bottom: 1px solid #efefef;

      .checkBoxLabel {
        display: flex;
        align-items: center;
        color: #212121;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .deleteButton {
        border: none;
        background-color: transparent;
        color: #d32f2f;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        cursor: pointer;
        text-align: left;
        margin-left: 30px;
      }
    }

    .listHeaderCheckout {
      display: flex;
      flex-direction: column;
      gap: 8px;

      .companyInfoBlock {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        @include for-mobile-max_620 {
          padding-right: 30px;
        }

        .companyOptionButtons {
          display: flex;
          gap: 16px;

          @include for-mobile-max_620 {
            display: none;
          }
        }
        .optionButton {
          white-space: nowrap !important;
          display: flex !important;
          gap: 12px !important;
          img {
            filter: brightness(0) saturate(100%) invert(15%) sepia(64%) saturate(2517%)
              hue-rotate(210deg) brightness(90%) contrast(91%);
          }
        }
      }

      .containerHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        flex-wrap: wrap;
        gap: 12px;

        .nameAndCount {
          display: flex;
          align-items: center;
          gap: 12px;
          flex-wrap: wrap;
        }

        .companyInfo {
          display: flex;
          align-items: center;
          column-gap: 12px;

          .companyName {
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #212121;
          }
        }

        .productsCount {
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
        }
      }
    }

    .productList {
      .productListHeader {
        border-bottom: none;
        padding-top: 18px;

        .productListCheckboxLabel {
          display: flex;
          align-items: center;
          gap: 8px;

          .image {
            border-radius: 16px;
          }

          .text {
            color: #212121;
            font-family: Montserrat;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px;
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin-top: 18px;

        .companyProductTotal {
          display: flex;
          gap: 20px;
          align-items: center;
          flex-wrap: wrap;

          .totalItem {
            max-width: 160px;

            .totalItemSelect {
              min-width: fit-content;
              z-index: 9;
              background-color: #ffffff;
            }
          }
        }
      }
    }
  }

  .listContainerUnable {
    display: flex !important;
    flex-direction: column !important;
    gap: 20px !important;
    .unableHeader {
      display: flex;
      gap: 10px;
      align-items: flex-start;

      .removeBtn {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 5px;
        cursor: pointer;
      }

      .unableMessage {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }
    }
  }
}

.checkoutBlock {
  padding-top: 20px;
  border-top: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  gap: 16px;

  > h2 {
    font-size: 16px;
    font-weight: 600;
  }

  .radioGroup {
    gap: 16px;

    .radioGroupLabel {
      display: flex;
      align-items: flex-start;
      margin: 0;
    }

    .label {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .radioLabel {
        margin-left: 8px;
      }
      .radioLabelActive {
        color: #143471;
      }

      .description {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
      }

      .descriptionActive {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 24px;
        color: #143471;
      }
    }

    .deliveryText {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }

    .sellerDelivery {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .autoOrderOptions {
        display: flex;
        flex-direction: column;
        gap: 12px;

        .autoOrderRow,
        .autoOrderRowCenter {
          display: flex;
          gap: 12px;
          align-items: start;

          .datepicker {
            position: relative;
            width: 100%;

            &[data-type='location'] {
              cursor: pointer !important;
            }

            &[data-type='location'] > label[data-shrink='false'] {
              transform: translate(56px, 14px) scale(1) !important;
            }

            & > label {
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 400;

              &[data-shrink='false'] {
                transform: translate(14px, 14px) scale(1);
              }
            }

            & > div {
              font-family: 'Montserrat';
              font-weight: 400;
              font-size: 16px;
              line-height: 23px;
              color: #212121;
              border-radius: 12px;
            }

            & > div > input {
              padding: 12.5px 16px;
            }

            & > div > fieldset {
              border-radius: 12px;
              border: 1px solid #dcdfe2;

              & > legend {
                font-family: 'Montserrat';
                font-style: normal;
                font-weight: 400;
              }
            }
          }

          .weeksCount {
            display: flex;
            align-items: center;
            gap: 8px;

            .weekText {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              white-space: nowrap;
            }

            .productCountInput input {
              text-align: center;
              padding: 8px 12px;
              font-family: 'Montserrat';
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #212121;
              -moz-appearance: textfield;

              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
            }
          }

          .weekDays {
            display: flex;
            align-items: center;
            width: 100%;
            flex-wrap: wrap;
            gap: 12px;

            .weekDayBtn {
              border: #ffffff !important;
              box-shadow: 0px 4px 14px 0px rgba(0, 0, 0, 0.08);
              border-radius: 12px !important;
              padding: 6px 12px !important;
              font-size: 14px !important;
              font-weight: 500 !important;
              color: #212121 !important;
              line-height: 24px !important;
              text-transform: capitalize;
            }

            .weekDayBtnActive {
              background-color: #bad4e7 !important;
              box-shadow: 0px 4px 24px 0px #0000000a;
              color: #18397a !important;
            }
          }
        }

        .autoOrderRow {
          @include for-mobile-max_620 {
            flex-direction: column;
          }
        }

        .autoOrderRowCenter {
          align-items: center;
          @include for-desktop-up-max_1200 {
            flex-direction: column;
            align-items: start !important;
          }
          @include for-tablet-landscape-max_1024 {
            flex-direction: row;
            align-items: center !important;
          }
          @include for-mobile-max_767 {
            flex-direction: column;
            align-items: start !important;
          }
        }
      }
    }
  }
}

.textField {
  position: relative;
  width: 100%;
  @include for-mobile-max_767 {
    // width: 90% !important ;
  }
  &[data-type='location'] {
    cursor: pointer !important;
  }
  &[data-type='location'] > label[data-shrink='false'] {
    transform: translate(56px, 14px) scale(1) !important;
  }
  & > label {
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 400;
    &[data-shrink='false'] {
      transform: translate(14px, 14px) scale(1);
    }
  }
  & > div {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #212121;
  }
  & > div > input {
    padding: 12.5px 16px;
  }
  & > div > fieldset {
    border: 1px solid #efefef;
    & > legend {
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 400;
    }
  }
}

.pickupSubfields {
  display: flex;
  align-items: center;
  gap: 12px;

  .selectInput {
    width: 100%;
  }
}

.checkbox {
  padding: 0px 9px;

  &:hover {
    background-color: transparent;
  }

  & > span > * {
    display: none;
  }
}

.checkoutBlockRadioGroup {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.checkoutBlockRadio {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.carbonContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 12px;

  .containerHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .carbonTitle {
      display: flex;
      gap: 8px;
    }

    .carbonPrice {
      white-space: nowrap;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
    }
  }
}

.optionsMenu {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;

  ul {
    padding: 0;
  }
  .menuItem {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.modalDescription {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.selectModeActions {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: space-between;
  width: 100%;

  button {
    @include for-mobile-max_767 {
      font-size: 12px !important;
    }
  }

  .openDisputeBtn {
    white-space: nowrap !important;
    display: flex !important;
    gap: 12px !important;
    align-items: center;
    svg {
      path {
        fill: #ffffff !important;
      }
    }
  }
}
